$(function(){

//無料ドクターメール相談
$('.blank').click(function(){
	var ua = $.browser;

	if(ua.msie){
		window.open($(this).attr('href') , '' , 'width=580 , height=733 , resizable=no , scrollbars=yes');
	}
	else {
		window.open($(this).attr('href') , '' , 'width=577 , height=746 , resizable=no , scrollbars=yes');
	}
	return false;

});

$('.blank2').click(function(){
	var ua = $.browser;

	if(ua.msie){
		window.open($(this).attr('href') , '' , 'width=980 , height=733 , resizable=no , scrollbars=yes');
	}
	else {
		window.open($(this).attr('href') , '' , 'width=977 , height=746 , resizable=no , scrollbars=yes');
	}
	return false;

});

if($('body').attr('id') == 'index'){
	$(window).load(function(){
		var $no2 = $('.rankinglist .no2');
		var $no3 = $('.rankinglist .no3');
		var $no4 = $('.rankinglist .no4');
		var $no5 = $('.rankinglist .no5');
		var no2Height = $no2.find('.rankinglist-item-wrapper').height();
		var no3Height = $no3.find('.rankinglist-item-wrapper').height();
		var no4Height = $no4.find('.rankinglist-item-wrapper').height();
		var no5Height = $no5.find('.rankinglist-item-wrapper').height();

		console.log(no2Height);
		console.log(no3Height);
		if(no2Height <= no3Height){
			$no2.find('.rankinglist-item-wrapper').height(no3Height);
			$no2.find('.rankinglist-item-wrapper').css('margin-bottom',8);
		} else {
			$no3.find('.rankinglist-item-wrapper').height(no2Height);
			$no3.find('.rankinglist-item-wrapper').css('margin-bottom',8);
		}

		if(no4Height <= no5Height){
			$no4.find('.rankinglist-item-wrapper').height(no5Height);
			$no4.find('.rankinglist-item-wrapper').css('margin-bottom',8);
		} else {
			$no5.find('.rankinglist-item-wrapper').height(no4Height);
			$no5.find('.rankinglist-item-wrapper').css('margin-bottom',8);
		}
	});
}

});
